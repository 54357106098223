import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import CardBuy from '../components/CardBuy';
import CarouselMk from '../components/CarouselMk';
import { useCategoriesContext } from '../context/categoriesContext';
import circle from '../images/location.svg';
import { formatThousands } from '../utils/Utils';
import TemplatePage from './TemplatePage';
import outOfStock from './../images/out-of-stock.png'
import proximamente from './../images/proximamente.png'
import { SolicitarInfo } from '../components/SolicitarInfo';
import profile from '../images/profile.svg'
import { InView } from "react-intersection-observer";
import { handleClick } from '../components/Utils';
import { providerUrl } from '../components/Utils';
import ListInfoExtra from '../components/ListInfoExtra';
import MapLocation from '../components/MapLocation';
import TableLastOrder from '../components/TableLastOrder';
import nf from '../images/404.png'
import { formatDate } from '../utils/Utils';
import { speciesImage } from '../components/Utils';


function ProductDetail() {
    const [inView, setInView] = React.useState(false);

    const { t } = useTranslation();
    const categoriesContext = useCategoriesContext();
    const categories = categoriesContext.categories;


    const Block = (props) => {
        const { forwardedRef } = props;
        return (
            <div className="viewport-block mr-2" ref={forwardedRef} >
                <div style={{ width: '100%' }}>
                    <CardBuy
                        productsid={productsid}
                    />
                </div>
            </div>
        );
    };


    const pathParams = useParams();
    const iddetail = pathParams.id

    // FETCH PARA productos-id
    const [productsid, setProductsid] = useState()

    useEffect(() => {
        fetch(`${process.env.REACT_APP_PRODUCT_ID}/${iddetail}`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                // Authorization: `Bearer ${UserService.getToken()}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 404) {
                        setProductsid(404);
                    } else {
                        // For other error statuses, handle them accordingly
                        console.error('Request failed with status ' + response.status);
                    }
                    throw new Error('Request failed with status ' + response.status);
                }
                return response.json();
            })
            .then(data => {
                setProductsid(data);
                createSchema(data);
            })
            .catch(error => {
                console.error('Error:', error.message);
            });
    }, []);

    const [schema, setSchema] = useState('');
    const [metatagsProduct, setMetatagsProduct] = useState({ title: "", desc: "", image: "" })

    const createSchema = (data) => {

        let images = [`${process.env.REACT_APP_MP_DOMAIN}${backgroundImage}`];
        for (let index = 0; index < data.productImageDtoList.length; index++) {
            images[index] = data.productImageDtoList[index].path;
        }
        let principalGallery = data.productImageDtoList.filter(x => x.principal);

        setMetatagsProduct({
            title: t("meta.title") + " | " + data.name,
            desc: data.summary || t("meta.home.description"),
            image: principalGallery.length > 0 ? principalGallery[0].path : backgroundImage
        });

        const schema = {
            "@context": "https://schema.org",
            "@type": "Product",
            name: data.name,
            image: images,
            description: data.summary,
            identifier: data.cadastre,
            category: data.category,
            countryOfAssembly: data.country,
            manufacturer: data.provider,
            offers: {
                "@type": "Offer",
                availability: data.stockCredits > 0 ? "https://schema.org/InStock" : "https://schema.org/SoldOut",
                priceSpecification: {
                    "@type": "UnitPriceSpecification",
                    price: data.price,
                    priceCurrency: "EUR",
                    referenceQuantity: {
                        "@type": "QuantitativeValue",
                        value: 1000,
                        unitCode: "KG",
                        valueReference: {
                            "@type": "QuantitativeValue",
                            value: 1000,
                            unitCode: "KG"
                        }
                    }
                }
            }
        };

        const schemaAsString = JSON.stringify(schema, null, 2);
        setSchema(schemaAsString)
    }


    function getColor(name) {
        return categories.find(element => element.name === name)?.color;
    }

    const handleSatelitalBig = (imgUrl) => {
        window.open(imgUrl, '_blank');
    };



    let active = productsid !== undefined ? productsid.active : ''
    let agotado = productsid !== undefined ? ((productsid.stockCredits - productsid.reservedCredits)) : ''


    let position = [parseFloat(productsid?.latitude), parseFloat(productsid?.longitude)]
    position = isNaN(position[0]) && isNaN(position[1]) ? [0, 0] : position
    const name = productsid?.name

    const [imgSatelital, setImgSatelital] = useState([]);
    useEffect(() => {
        if (productsid !== undefined && productsid.gallery !== undefined) {
            setImgSatelital(productsid.gallery.filter(img => img.satelital === true));
            // console.log(imgSatelital);
        }
    }, [productsid])

    function removeAccents(text) {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    let forestTypeLowerCase
    if (productsid && productsid.forestType) {
        forestTypeLowerCase = removeAccents(productsid.forestType)
            .toLowerCase()
            .replace(/\s/g, '');

    }




    const backgroundImage = speciesImage(forestTypeLowerCase);
    function obtenerNombreArchivo(url) {
        const partesUrl = url.split("/");
        const ultimaParte = partesUrl[partesUrl.length - 1];
        if (!ultimaParte.includes(".")) {
            const nombreBase = "Documento";
            const timestamp = Date.now();
            return `${nombreBase}-${timestamp}`;
        } else {
            const indiceUltimoPunto = ultimaParte.lastIndexOf(".");
            if (indiceUltimoPunto !== -1) {
                return ultimaParte.substring(0, indiceUltimoPunto);
            } else {
                return ultimaParte;
            }
        }
    }


    return (
        <>
            {(!inView && active === true && agotado >= 0) &&
                <div id="divFlotante" className='w-full'>
                    <a id="btnCompraFlotante" className='bntFlotante text-white text-center w-full' href='#sidebar-detail-card'>
                        {t("detail.addCarrito")}
                    </a>
                </div>
            }

            <div className="flex text-airco2-general">
                <Helmet>
                    <script type="application/ld+json">{schema}</script>
                </Helmet>

                <div className="relative flex flex-col flex-1 " style={{ maxWidth: '100vw' }}>
                    <TemplatePage
                        // padding={'pt-header'}
                        padding={active === false || agotado <= 0 ? 'pt-[14vh]' : 'pt-[14vh] md:pt-header'}
                        className={'container'}
                        h1={metatagsProduct.title}
                        title={metatagsProduct.title}
                        desc={metatagsProduct.desc}
                        image={metatagsProduct.image}>


                        {productsid === undefined ? t("cat.cargando")
                            :
                            productsid === 404 ?
                                <div className='flex-row'>
                                    <div className='flex justify-center'>
                                        <img alt='notFound' src={nf} width={'750px'}></img>
                                    </div>
                                    <div className='w-full flex flex-row justify-center text-airco2-filter-content text-md'>
                                        <div className='px-2'>
                                            <span>{t("detail.productoNoDisponible")}</span>
                                            <p />
                                            <p className='flex justify-center'>{t("detail.volverAl")}
                                                <Link onClick={handleClick} to={`/catalogo`}>
                                                    <span style={{ color: '#8DD4BD', fontStyle: 'italic', fontWeight: 'bold' }}>&nbsp;{t("detail.catalogo")} </span>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="mt-1 md:mt-4 lg:mt-1 flex flex-col lg:flex-row lg:space-x-8 xl:space-x-16 flex justify-center">

                                    {/* Content */}
                                    <div className='md:basis-9/12'>



                                        {/* Meta */}
                                        <div className=" flex space-y-3 sm:items-center justify-between sm:space-y-0 mb-1 ">
                                            <Row className="flex w-full">
                                                <ul className=' flex  items-center justify-between mb-3'>

                                                    <li className='block md:hidden  font-bold mb-2 pr-7' style={{ fontSize: '1.4rem' }}>

                                                        {productsid.name} ✨
                                                    </li>
                                                    {productsid.category !== null &&
                                                        <li className='block md:hidden'>
                                                            <div className="inline-flex items-center text-xs font-medium   rounded-lg text-center px-2 py-1" style={{ backgroundColor: `${getColor(productsid.category)}` }}>
                                                                <svg className=" inline-block mb-1" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3" />
                                                                </svg>
                                                                <span className='text-white'>{productsid.category}</span>
                                                            </div>
                                                        </li>
                                                    }
                                                </ul>

                                                {/* Image */}
                                                <Col md={5} lg={6}>
                                                    <figure className="relative w-full flex justify-center md:justify-start px-1 py-1" style={{ backgroundColor: 'rgb(141, 212, 189,0.3)' }}>
                                                        {productsid.productImageDtoList.length === 0 ?
                                                            <img className="rounded-sm" src={backgroundImage} width="100%" alt="Product" style={{ maxHeight: '100%', objectFit: 'contain' }} />
                                                            :
                                                            productsid.productImageDtoList.map((y, index) =>
                                                                (y.principal === true) &&

                                                                <img className="rounded-sm" src={y.path} width="100%" alt="Product" style={{ maxHeight: '360px', objectFit: 'contain' }} />
                                                            )
                                                        }

                                                        {(!productsid.active)
                                                            && <div className='absolute h-full w-full' style={{ backgroundColor: 'rgb(110 110 110 / 80%)' }}>
                                                                <div className='flex justify-center items-center h-full text-white'>
                                                                    <span className='text-xl tracking-widest		uppercase'>{t("detail.proximamente")}</span></div>
                                                            </div>}


                                                        {(productsid.stockCredits === null || productsid.stockCredits <= 0 || productsid.stockCredits === "") && productsid.active
                                                            && <div className='absolute h-full w-full' style={{ backgroundColor: 'rgb(110 110 110 / 80%)' }}>
                                                                <div className='flex justify-center items-center h-full text-white'><span className='text-xl tracking-widest		uppercase'>{t("detail.sinstock")}</span></div>
                                                            </div>}

                                                    </figure>
                                                </Col>


                                                <Col className='relative' md={7} lg={6}>
                                                    <ul className=' pl-2 flex  items-center justify-between'>

                                                        <li className='hidden md:block  font-bold mb-2 pr-4' style={{ fontSize: '1.5rem' }}>

                                                            {productsid.name} ✨
                                                        </li>
                                                        {(productsid.category !== null) &&
                                                            <li className='hidden md:block'>
                                                                <div className="inline-flex items-center text-xs font-medium  rounded-lg text-center px-2 py-1" style={{ backgroundColor: `${getColor(productsid.category)}` }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bolt" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3" />
                                                                    </svg>
                                                                    <span className='text-white'>{productsid.category}</span>
                                                                </div>
                                                            </li>
                                                        }
                                                    </ul>

                                                    <ul className='pl-2 mt-2 flex ' >
                                                        <li className='flex gap-1' >
                                                            <img alt='circulo-icono' src={circle} width={'16px'} ></img>
                                                            <a href={`https://maps.google.com/?q=${productsid.latitude},${productsid.longitude}`} aria-label='irige a googleMaps' target='_blank' rel='noopener noreferrer' className="text-airco2-general">
                                                                <p>{productsid.city}, {productsid.state}</p>
                                                            </a>
                                                        </li>
                                                    </ul>

                                                    {productsid.active && (productsid.stockCredits !== null && productsid.stockCredits > 0 && productsid.stockCredits !== "")
                                                        && <>
                                                            <ul className='flex pl-2 mt-4 italic flex-wrap justify-between'>

                                                                <li className='italic text-airco2-principal'>  <span className='font-bold'>{formatThousands(productsid.price)}</span>€/ {t("detail.toneladaCo2")} </li>


                                                                <li className='italic text-airco2-secondary'>
                                                                    {productsid.reservedCredits > 0 && <>
                                                                        <span className='font-bold'> {formatThousands((productsid.stockCredits - productsid.reservedCredits))}</span>{t("detail.tdisponibles")} /</>}
                                                                    <span className='font-bold'>{formatThousands(productsid.stockCredits)}</span>{t("detail.stock")}</li>

                                                            </ul>
                                                            <ul className=' flex flex-wrap justify-between pl-2 mt-2 mb-2 italic text-left '>
                                                                <li className='hidden md:block'></li>
                                                                <li className=''> {t("detail.absorcion")} <span className='font-semibold'>{formatThousands(productsid.totalCredits)}t</span> </li>
                                                            </ul>
                                                        </>
                                                    }


                                                    <Link onClick={handleClick} to={providerUrl(productsid)} className='text-airco2-general lg:absolute bottom-0	'>
                                                        <ul className='pt-2 pl-2 flex items-center gap-1 italic font-bold' >

                                                            <li><img className=' rounded-full' src={(productsid.providerImage !== "" && productsid.providerImage !== null) ? productsid.providerImage : profile} width={'70'} alt='imgProvider'></img></li>
                                                            <li className=''>{productsid.provider}</li>
                                                        </ul>
                                                    </Link>
                                                </Col>
                                            </Row>

                                        </div>


                                        {/* Product content */}
                                        <div>

                                            <p className="mb-2 mt-2">
                                                {productsid.summary}
                                            </p>
                                            <p className="mb-6 mt-2" dangerouslySetInnerHTML={{ __html: productsid.description }}>

                                            </p>

                                            <Row>

                                                <ul className="flex flex-wrap">
                                                    <Col className='min-w-[200px]'>

                                                        <li className='flex inline mb-2 mr-2' >
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-stack-3" width="22" height="22" viewBox="0 0 24 24" stroke="#8dd4bd" strokeWidth="1.5" fill="none">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M4 10l8 4l8 -4" />
                                                                <path d="M4 18l8 4l8 -4" />
                                                                <path d="M4 14l8 4l8 -4" />
                                                                <path d="M4 12l8 4l8 -4" />
                                                                <path d="M4 20l8 4l8 -4" />
                                                                <path d="M4 16l8 4l8 -4" />
                                                            </svg>
                                                            <span className='ml-2 pr-1'> {productsid.cadastre} </span>
                                                        </li>

                                                        {productsid.forestType != null && productsid.forestType.length !== 0 ?
                                                            <li className='flex inline mb-2'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trees" width="22" height="22" viewBox="0 0 24 24" stroke="#8dd4bd" strokeWidth="1.5" fill="none">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M16 5l3 3l-2 1l4 4l-3 1l4 4h-9" />
                                                                    <line x1="15" y1="21" x2="15" y2="18" />
                                                                    <path d="M8 13l-2 -2" />
                                                                    <path d="M8 12l2 -2" />
                                                                    <path d="M8 21v-13" />
                                                                    <path d="M5.824 15.995a3 3 0 0 1 -2.743 -3.69a2.998 2.998 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a2.997 2.997 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z" />
                                                                </svg>
                                                                <span className='ml-2'>{productsid.forestType} </span>
                                                            </li>
                                                            :
                                                            ""

                                                        }

                                                        <li className='flex inline mb-2'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dimensions" width="22" height="22" viewBox="0 0 24 24" stroke="#8dd4bd" strokeWidth="1.5" fill="none"   >
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M3 5h11" />
                                                                <path d="M12 7l2 -2l-2 -2" />
                                                                <path d="M5 3l-2 2l2 2" />
                                                                <path d="M19 10v11" />
                                                                <path d="M17 19l2 2l2 -2" />
                                                                <path d="M21 12l-2 -2l-2 2" />
                                                                <rect x="3" y="10" width="11" height="11" rx="2" />
                                                            </svg>
                                                            <span className='ml-2'>{formatThousands(productsid.size)} {t("detail.hectareas")}</span>
                                                        </li>
                                                    </Col>

                                                    <Col className='min-w-[200px]'>
                                                        <li className='flex inline mb-2'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-stack-3" width="22" height="22" viewBox="0 0 24 24" stroke="#8dd4bd" strokeWidth="1.5" fill="none">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M12 2l-8 4l8 4l8 -4l-8 -4" />
                                                                <path d="M4 10l8 4l8 -4" />
                                                                <path d="M4 18l8 4l8 -4" />
                                                                <path d="M4 14l8 4l8 -4" />
                                                            </svg>
                                                            <span className='ml-2'> {t("detail.verified")} {productsid.verifiedBy}</span>
                                                        </li>


                                                        <li className='flex inline mb-2'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-check" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M11.5 21h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v6" />
                                                                <path d="M16 3v4" />
                                                                <path d="M8 3v4" />
                                                                <path d="M4 11h16" />
                                                                <path d="M15 19l2 2l4 -4" />
                                                            </svg>
                                                            <span className='ml-2'> {t("detail.fechaValidacion")}{formatDate(productsid.validationDate, 2)}</span>
                                                        </li>

                                                        <li className='flex inline mb-2'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-stats" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" />
                                                                <path d="M18 14v4h4" />
                                                                <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                                                                <path d="M15 3v4" />
                                                                <path d="M7 3v4" />
                                                                <path d="M3 11h16" />
                                                            </svg>
                                                            <span className='ml-2'> {t("detail.proximaValidacion")}{formatDate(productsid.expireContractDate, 2)}</span>
                                                        </li>
                                                    </Col>
                                                </ul>
                                            </Row>


                                            {(productsid.sustainabilityGoalDtoList.length !== 0) &&

                                                <div className="mt-6">
                                                    <h2 className='font-bold text-xl'>{t("detail.buenasPracticas")}</h2>
                                                    <ul className=" mt-3 grid grid-cols-2">
                                                        {productsid.sustainabilityGoalDtoList.map((y, index) =>
                                                            <li key={index} className=' hidden sm:block list-objetivos-sost  flex-column py-1' >
                                                                <h3 className='flex items-center mb-4 gap-2'>
                                                                    <img alt='iconosostenibilidad' src={y.image} width={'50px'} />
                                                                    <span className='font-bold text-lg'> {y.name}</span>
                                                                </h3>
                                                                <div className='flex' >
                                                                    <span className='italic'>  {y.desc} </span>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                    <ul className="mt-3">
                                                        <li className=' block sm:hidden py-1 list-objetivos-sost-mvl' >
                                                            {productsid.sustainabilityGoalDtoList.map((y, index) =>

                                                                <span key={index}>

                                                                    <h3 className='flex gap-2 items-center mb-1 mt-3'>
                                                                        <img alt='iconosostenibilidad' src={y.image} width={'50px'} />
                                                                        <span className='font-bold text-lg'> {y.name}</span>
                                                                    </h3>
                                                                    <span className='italic'>  {y.desc} </span>
                                                                </span>

                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>

                                            }


                                            {(productsid?.documents && productsid?.documents?.length !== 0) &&

                                                <div className="mt-6">
                                                    <h2 className='font-bold text-xl'>{t("detail.documentation")}</h2>
                                                    <ul className=" mt-3 ">
                                                        {productsid.documents.sort((a, b) => {
                                                            const nombreA = a.name || obtenerNombreArchivo(a.path);
                                                            const nombreB = b.name || obtenerNombreArchivo(b.path);
                                                            if (nombreA < nombreB) return -1;
                                                            if (nombreA > nombreB) return 1;
                                                            return 0;
                                                        }).map((y, index) =>
                                                            <li key={index} className='flex items-center gap-2' >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-info" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                                    <path d="M11 14h1v4h1" />
                                                                    <path d="M12 11h.01" />
                                                                </svg> <a href={y.path} target='_blank'>{y.name || obtenerNombreArchivo(y.path)} </a>

                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>

                                            }
                                        </div>




                                        {(productsid.context || productsid.zone || productsid.monitoring || productsid.benefits || productsid.calculation || productsid.management) &&
                                            <div className='mt-6'>
                                                <span className='font-bold text-xl mb-3'>{t("detail.informe")}</span>
                                                <ListInfoExtra
                                                    productsid={productsid}>

                                                </ListInfoExtra>
                                            </div>
                                        }

                                        <div className='flex justify-center'>
                                            <a href='https://market.airco2.earth/pdf/MetodologiaAirCO2.pdf' target="_blank">
                                                <button type='button' className={`btn bg-airco2-principal text-white mt-4`} >{t("detail.conoceMetodologia")}</button>
                                            </a>
                                        </div>

                                        {(position[0] !== 0 && position[1] !== 0) &&
                                            <div className='mt-6 mb-6 '>
                                                <h2 className='font-bold text-xl mb-3'> {t(`infoExtra.localizacion`)}</h2>
                                                <Row className='space-y-6 md:space-y-0 '>
                                                    <Col xs={12} md={imgSatelital.length > 0 ? 6 : 12} >
                                                        <MapLocation
                                                            position={position} name={name}
                                                        ></MapLocation>

                                                    </Col>
                                                    {
                                                        imgSatelital.length > 0 ?
                                                            <Col xs={12} md={6} >
                                                                <div className='flex flex-col h-full' style={{ height: '30vh' }}>
                                                                    <>
                                                                        <img alt='imagenSatelital' src={imgSatelital[0].path}
                                                                            className='object-contain w-full h-full	cursor-zoom-in'
                                                                            onClick={() => handleSatelitalBig(imgSatelital[0].path)}
                                                                            style={{ height: '30vh' }}></img>
                                                                    </>
                                                                </div>
                                                            </Col>
                                                            : ""}
                                                </Row>
                                            </div>
                                        }


                                        {(productsid.orderLineDtoCustomer?.length > 0) &&
                                            <div className='mt-6 mb-6' >
                                                <h2 className='font-bold text-xl mb-3'>{t("detail.lastCompras")}</h2>
                                                <TableLastOrder
                                                    ordersid={productsid.orderLineDtoCustomer}
                                                >
                                                </TableLastOrder>
                                            </div>
                                        }






                                        {/* Related */}
                                        {(productsid.productImageDtoList.filter((x) => x.principal !== true && x.satelital === false).length > 0) &&
                                            <div className='mt-6 mb-6'>

                                                <h2 className='font-bold text-xl mb-3'>{t("detail.galeria")}</h2>
                                                <div className='mb-4 max-w-full'>
                                                    <CarouselMk
                                                        imagenProduct={productsid.productImageDtoList}>
                                                    </CarouselMk>
                                                </div>
                                            </div>

                                        }
                                    </div>



                                    {/* Sidebar */}
                                    <div className='sidebar-detail-card md:basis-3/12 mb-5 md:mb-1 ' id='sidebar-detail-card'  >
                                        <div className=" sidebar-detail-content p-4 shadow-lg rounded-sm border border-gray-200 lg:w-100 xl:w-100">
                                            <div className="text-sm  font-semibold mb-3 text-center">{t("detail.neutraliza")} {productsid.name}</div>
                                            {/* <ul className="space-y-2 sm:flex sm:space-y-0 sm:space-x-2 lg:space-y-2 lg:space-x-0 lg:flex mb-4 justify-between italic mt-4"> */}

                                            {!productsid.active ? <div className='text-center'>
                                                <span className='text-xl tracking-widest uppercase'>{t("detail.proximamente")}</span>
                                                <img alt='proximamente' src={proximamente} className='mx-auto'></img>
                                                <SolicitarInfo></SolicitarInfo>
                                            </div> :
                                                (productsid.stockCredits === null || productsid.stockCredits <= 0 || productsid.stockCredits === "")
                                                    ?
                                                    <div className='text-center'>
                                                        <span className='text-xl tracking-widest uppercase'>{t("detail.sinstock")}</span>
                                                        <img alt='outStock' src={outOfStock} className='mx-auto'></img>
                                                    </div> :
                                                    <>
                                                        <ul className="flex justify-between mb-4 italic mt-4 text-sm">
                                                            <li className=''>
                                                                <span className='text-airco2-principal  '><span className='font-bold'>{formatThousands(productsid.price)}</span> €/{t("detail.tonelada")}</span>
                                                            </li>
                                                            <li className='text-airco2-secondary '>
                                                                <span className='font-bold'> {formatThousands((productsid.stockCredits - productsid.reservedCredits))}</span>{t("detail.toneladaD")}
                                                            </li>


                                                        </ul>

                                                        <InView onChange={setInView}>
                                                            <Block />
                                                        </InView>

                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                        }



                    </TemplatePage>
                </div>


            </div>
        </>
    );
}


export default ProductDetail;