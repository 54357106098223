import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import metatag_image from "./../images/Slide01.webp";
import i18n from "./../translations/i18n";

export default function PageMetatags({ titleDynamic, descDynamic, image }) {
  const { t } = useTranslation();

  const { pathname } = useLocation(); // Importa useLocation

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [robotsIndex, setRobotsIndex] = useState(false);

  useEffect(() => {
    getMetatags();
  }, [pathname, titleDynamic, descDynamic]);

  i18n.on("initialized", () => {
    getMetatags();
  });

  function getMetatags() {
    let value = undefined;
    // let titleValue = undefined;
    // let descriptionValue = undefined;
    let robotsIndexValue = false;
    const url = pathname.toLowerCase();

    if (url === "/") {
      value = "home.";
      robotsIndexValue = true;
    } else if (url === "/catalogo") {
      value = "catalogo.";
      robotsIndexValue = true;
    } else if (url === "/map") {
      value = "map.";
      robotsIndexValue = true;
    } else if (url === "/forest") {
      value = "forest.";
      robotsIndexValue = true;
    } else if (url === "/legal") {
      value = "legal.";
      robotsIndexValue = true;
    } else if (url === "/privacy") {
      value = "privacy.";
      robotsIndexValue = true;
    } else if (url === "/terms") {
      value = "terms.";
      robotsIndexValue = true;
    } else if (url.startsWith("/product/")) {
      robotsIndexValue = true;
    } else if (url.startsWith("/provider/")) {
      robotsIndexValue = true;
    } else if (url === "/login") {
      value = "login.";
      robotsIndexValue = false;
    } else if (url === "/register") {
      value = "register.";
      robotsIndexValue = false;
    } else if (url === "/reset-password") {
      value = "password.";
      robotsIndexValue = false;
    } else if (url === "/shopping") {
      value = "shopping.";
      robotsIndexValue = false;
    } else if (url.startsWith("/finishorder")) {
      value = "finishOrder.";
      robotsIndexValue = false;
    } else if (url === "/account/orders") {
      value = "orders.";
      robotsIndexValue = false;
    } else if (url === "/account/profile") {
      value = "profile.";
      robotsIndexValue = false;
    } else if (url === "/account/address") {
      value = "address.";
      robotsIndexValue = false;
    } else if (url === "/stripe/connect/verified") {
      value = "stripeConnect.";
      robotsIndexValue = false;
    } else if (url === "/stripe/connect/retry") {
      value = "stripeConnectFail.";
      robotsIndexValue = false;
    } else if (url === "/paymentresponse") {
      value = "stripePayment.";
      robotsIndexValue = false;
    } else {
      value = "home.";
      robotsIndexValue = false;
    }

    setTitle(titleDynamic ? titleDynamic : t("meta." + value + "title"));
    setDesc(descDynamic ? descDynamic : t("meta." + value + "description"));
    setRobotsIndex(robotsIndexValue);
  }

  if (image === undefined || image === "") {
    image = metatag_image;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={desc} />
      <link rel="canonical" href={window.location.href} />
      {robotsIndex ? (
        <meta name="robots" content="index, follow" />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={window.location.href} />

      <meta name="twitter:card" content="photo" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={window.location.href} />
    </Helmet>
  );
}
