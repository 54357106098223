import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ProviderBody from '../components/ProviderBody';
import profile from '../images/profile.svg';
import TemplatePage from './TemplatePage';

export default function Provider() {
  const { t } = useTranslation();

  const pathParams = useParams();
  const idprovider = pathParams.id



  // FETCH PARA provider-id

  const [providerid, setproviderid] = useState()
  const [metatagsProduct, setMetatagsProduct] = useState({ title: "", desc: "", image: "" })



  useEffect(() => {
    fetch(`${process.env.REACT_APP_MP_PROVIDER}/${idprovider}`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // Authorization: `Bearer ${UserService.getToken()}`,
      },
    })
      .then(j => j.json())
      .then(r => {

        setproviderid(r)
        let principalGallery = r.gallery.filter(x => x.principal);
        setMetatagsProduct({
          title: "Market airCO2 | " + r.name,
          desc: r.summary || t("meta.home.description"),
          image: principalGallery.length > 0 ? principalGallery[0].path : profile
        });

      }


      )

  }, []
  )

  return (
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">

      <TemplatePage h1={metatagsProduct.title}
        title={metatagsProduct.title}
        desc={metatagsProduct.desc}
        image={metatagsProduct.image}>

        {(providerid !== undefined) &&
          <ProviderBody
            providerid={providerid} />
        }
      </TemplatePage>

    </div>

  )
}
